<!--
 * @Author: 段海兵
 * @Date: 2022-11-08 16:09:07
 * @LastEditors: 段海兵
 * @Description: 表格查询组件
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="search-list">
    <search-form ref="searchForm" :loading="loading" :search="search" @searched="handleSearched" />
    <search-list ref="searchList" :loading="loading" :data="tableData" :colums="colums" :params="params" @page-change="handlePageChange">
      <template v-for="item in columsSlot" v-slot:[item.name]="{ data }">
        <slot :name="item.name" :data="data" />
      </template>
    </search-list>
  </div>
</template>

<script>
import { getApi } from '@/utils/request'
import SearchForm from './searchForm'
import SearchList from './searchList'
export default {
  name: 'QueryTable',
  components: {
    SearchForm,
    SearchList
  },
  props: {
    search: {
      type: Array,
      default: () => ([])
    },
    colums: {
      type: Array,
      default: () => ([])
    },
    params: {
      type: Object,
      default: () => ({
        pageSize: '20',
        pageNumber: '2'
      })
    },
    request: {
      type: Object,
      default: () => ({
        soc: '',
        version: 'V1.0'
      })
    },
    firstRequest: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {},
      pager: {},
      tableData: [],
      loading: false
    }
  },
  computed: {
    columsSlot() {
      return this.colums.filter(item => (!!item.slot))
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    // 点击查询或者重置
    handleSearched(form) {
      this.form = form
      this.$refs.searchList.resetPage()
    },
    // 分页变更时
    handlePageChange(pager) {
      this.pager = pager
      this.getTableData()
    },
    // 清空表单并初始化分页重新查询
    handleSearch() {
      if (this.search?.length) {
        this.$refs.searchForm.resetForm()
      } else {
        this.handleSearched({})
      }
    },
    // 查询表格数据
    getTableData() {
      const { soc, version = 'V1.0' } = this.request
      this.loading = true
      getApi(soc, version, {
        ...this.params,
        ...this.form,
        ...this.pager
      }).then(kdjson => {
        this.loading = false
        const flag = kdjson.flag
        const items = kdjson.items
        if (flag === '1' && items) {
          this.tableData = items
        } else {
          this.tableData = []
        }
      }).catch(() => {
        this.loading = false
        this.tableData = []
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
