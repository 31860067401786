<template>
  <div class="withdraw">
    <el-dialog
      class="withdraw-dialog"
      title="出金"
      width="900px"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <el-form ref="withdrawForm" :model="form" status-icon :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称" prop="name">
              <span>{{ accountInfo.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可取余额" prop="kqye">
              <span>{{ moneyInfo.zjky | fixNum(2) }}元</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收款方户名" prop="account_name">
              <span>{{ accountInfo.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款方卡号" required prop="bank_account_no">
              <el-input v-model="form.bank_account_no" placeholder="收款方卡号" prefix-icon="el-icon-bank-card" clearable autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行名称" required prop="bank_code">
              <el-select v-model="form.bank_code" class="w-100" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in bankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支行号" required prop="bank_line_no">
              <!-- 支行查询 -->
              <sub-brach
                v-model="form.bank_line_no"
                :params="{
                  bankno: form.bank_code
                }"
                :before-search="handleBeforeSearchBrach"
                @selected="handleBrachSelected"
              />
              <!-- <el-input v-model="form.bank_line_no" placeholder="支行号" prefix-icon="el-icon-school" clearable autocomplete="off"><el-button slot="append" icon="el-icon-search" /></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="支行名称" prop="bank_branch">
              <span>{{ form.bank_branch }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出金金额" required prop="applicationamount">
              <el-input v-model="form.applicationamount" placeholder="金额" prefix-icon="el-icon-money" clearable autocomplete="off"><span slot="append">元</span></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = fasle">取消</el-button>
        <el-button type="success" :loading="loading" @click="handleSignSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApi } from '@/utils/request'
// import kdes from '@/utils/kjax.des'
import { requiredRules, moneyRules } from '@/utils/validate'
import { fixNum } from '@/utils'
import { mapState } from 'vuex'
import SubBrach from './sub-brach'
export default {
  name: 'Withdraw',
  components: {
    SubBrach
  },
  props: {
    moneyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      bankOptions: [],
      form: {
        bank_account_no: '',
        bank_code: '',
        bank_line_no: '',
        bank_branch: '',
        applicationamount: ''
      },
      rules: {
        bank_account_no: [
          requiredRules('收款方卡号')
        ],
        bank_code: [
          requiredRules('银行名称')
        ],
        bank_line_no: [
          requiredRules('支行号')
        ],
        applicationamount: [
          requiredRules('出金金额'),
          moneyRules('出金金额')
        ]
      },
      loading: false
    }
  },
  computed: mapState({
    accountInfo: state => state.account.accountInfo
  }),
  created() {
    this.$bus.$on('logined', () => {
      this.getBanks()
    })
  },
  methods: {
    handleShow() {
      if (this.$refs.withdrawForm && this.$refs.withdrawForm.resetFields) {
        this.$refs.withdrawForm.resetFields()
      }
      this.dialogVisible = true
    },

    // 点击查询分行号
    handleBeforeSearchBrach() {
      let status = true
      this.$refs.withdrawForm.validateField('bank_code', (err) => {
        if (err) {
          status = false
        }
      })
      return status
    },
    // 选中分行数据时
    handleBrachSelected(row) {
      this.form.bank_branch = row.interbankname
    },

    getBankName(bank_code) {
      const filter = this.bankOptions.filter(item => (item.value === bank_code))
      return filter && filter[0] ? filter[0].label : ''
    },

    handleSignSubmit() {
      this.$refs.withdrawForm.validate((valid) => {
        if (valid) {
          this.$confirm(`请确认收款方户名<span class="cl-orange font-medium mr-50 ml-50">${this.accountInfo.name}</span>收款方卡号<span class="cl-orange font-medium mr-50 ml-50">${this.form.bank_account_no}</span>出金金额<span class="cl-orange font-medium mr-50 ml-50">${fixNum(this.form.applicationamount, 2)}</span>，户名不一致会影响出金，如不一致请联系交易所修改！是否确认无误继续提交？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(() => {
            this.loading = true
            getApi('kingdom.kfts.set_out_exe_uc_12002_bjjs', 'V1.0', {
              ...this.form,
              account_name: this.accountInfo.name,
              bank_name: this.getBankName(this.form.bank_code),
              capitalaccount: this.moneyInfo.capitalaccount,
              transactionaccountid: this.accountInfo.transactionaccountid,
              acceptmethod: '4',
              currencytype: '0'
            }).then((kdjson) => {
              this.loading = false
              const flag = kdjson.flag
              const items = kdjson.items
              if (flag === '1' && items) {
                this.dialogVisible = false
                // window.open(items[0].cashierUrl)
                // 刷新资金信息
                this.$emit('withdrawed')
                this.$message({
                  message: kdjson.msg,
                  type: 'success'
                })
              } else {
                this.$message({
                  message: kdjson.msg || '出金失败！',
                  type: 'error'
                })
              }
            }).catch((kdjson) => {
              this.loading = false
              this.$message({
                message: kdjson.msg,
                type: 'error'
              })
            })
          }).catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 获取银行信息
    getBanks(params = {}) {
      getApi('kingdom.kfts.get_bankno_info', 'V1.0', {
        ...params
      }).then((kdjson) => {
        const flag = kdjson.flag
        const items = kdjson.items
        if (flag === '1' && items) {
          this.bankOptions = items.map(item => ({
            value: item.bankeno || item.bankno,
            label: item.bankname
          }))
        } else {
          this.bankOptions = []
        }
      }).catch((kdjson) => {
        this.bankOptions = []
      })
    }
  }
}
</script>

<style scoped lang="scss">
.withdraw-dialog {
  .withdraw-alert{
    margin-bottom: 22px;
  }
  .no-margin {
    margin-bottom: 0;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
