<!--
 * @Author: 段海兵
 * @Date: 2022-10-27 13:35:26
 * @LastEditors: 段海兵
 * @Description: 查看公告弹窗
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <el-dialog
    class="notice-dialog"
    :title="noticeInfo.noticeabstract"
    width="60%"
    :visible.sync="dialogVisible"
    append-to-body
    destroy-on-close
  >
    <el-divider class="time">{{ noticeInfo.publishdate | formatDate }}</el-divider>
    <div class="notice-content scrollbar">
      <div class="rich-txt" v-html="setRichTxtUrl(noticeInfo.noticemsg)" />
      <div v-if="!!noticefile.length" class="files">
        <el-divider class="files-title" content-position="left"><i class="el-icon-paperclip" />附件</el-divider>
        <div class="files-items flex wrap">
          <div v-for="(file, i) in noticefile" :key="i" class="files-item text-ellipsis">
            <el-link class="" type="primary" :href="setSoruceUrl(file.filepath)" :icon="linkIcon(file.filepath)" target="_blank" :download="file.filename" :underline="false">{{ file.filename }}</el-link>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { setSoruceUrl, setRichTxtUrl } from '@/utils'
export default {
  name: 'Notice',
  data() {
    return {
      dialogVisible: false,
      noticeInfo: {}
    }
  },
  computed: {
    noticefile() {
      return this.noticeInfo.noticefile ? JSON.parse(this.noticeInfo.noticefile) : []
    },
    linkIcon() {
      return href => {
        if (/(.png$)|(.jpeg$)|(.jpg$)|(.bif$)|(.bpm$)|(.tif$)|(.gif$)/.test(href)) {
          return 'el-icon-picture-outline'
        } else if (/(.mp4$)|(.rmvb$)|(.wmv$)|(.rm$)|(.3gp$)|(.mpg$)|(.avi$)/.test(href)) {
          return 'el-icon-video-play'
        } else {
          return 'el-icon-document'
        }
      }
    }
  },
  created() {},
  methods: {
    setSoruceUrl,
    setRichTxtUrl,
    handleShow(params) {
      this.dialogVisible = true
      this.noticeInfo = params
    }
  }
}
</script>

<style scoped lang="scss">
.notice-dialog {
  ::v-deep {
    .el-dialog__header{
      padding-right: 2.625rem;
    }
    .el-dialog__body {
      position: relative;
    }
    .time {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0.5rem 0;
      background-color: #f6f7f8;
      .el-divider__text {
        font-size: 0.875rem;
        color: #c0c4cc;
      }
    }
  }
  .notice-content{
    height: 25rem;
    overflow: hidden auto;
    font-size: 1.0625rem;
    .files {
      padding-top: 1rem;
      ::v-deep {
        .files-title {
          margin: 1rem 0;
          .el-divider__text{
            font-size: 1.125rem;
          }
        }
        .el-link {
          font-size: 1rem;
          display: inline;
        }
      }
      .files-items{
        padding-top: 0.5rem;
        .files-item {
          width: 50%;
          padding: 0 0 0.5rem 0.5rem;
        }
      }
    }
  }
}
</style>
