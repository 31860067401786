<!--
 * @Author: 段海兵
 * @Date: 2022-10-27 13:35:26
 * @LastEditors: 段海兵
 * @Description: 支行查询弹窗
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div>
    <el-input :value="value" placeholder="支行号" prefix-icon="el-icon-school" readonly :disabled="disabled" clearable autocomplete="off"><el-button slot="append" icon="el-icon-search" :disabled="disabled" @click="handleShow" /></el-input>
    <el-dialog
      class="sub-brach-dialog"
      title="支行"
      width="60%"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <query-table ref="queryTable" :search="search" :colums="colums" :params="paramsMap" :request="request">
        <template v-slot:operator="{ data }">
          <el-button type="success" size="mini" @click="handleSelected(data)">选择</el-button>
        </template>
      </query-table>
    </el-dialog>
  </div>
</template>

<script>
// import { getApi } from '@/utils/request'
import QueryTable from '@/components/queryTable'
export default {
  name: 'SubBrach',
  components: {
    QueryTable
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    beforeSearch: {
      type: Function,
      default: () => () => (true)
    }
  },
  data() {
    return {
      dialogVisible: false,
      search: [
        {
          type: 'input',
          label: '支行编号',
          name: 'interbankno'
        },
        {
          type: 'input',
          label: '支行名称',
          name: 'interbankname'
        }
      ],
      colums: [
        {
          label: '银行编号',
          name: 'bankno',
          minWidth: 120
        },
        {
          label: '银行名称',
          name: 'bankname',
          minWidth: 220
        },
        {
          label: '支行号',
          name: 'interbankno',
          minWidth: 120
        },
        {
          label: '支行名称',
          name: 'interbankname',
          minWidth: 220
        },
        {
          label: '操作',
          name: 'operator',
          class: 't-c',
          width: 80,
          slot: true
        }
      ],
      request: {
        soc: 'kingdom.kfts.get_interbankno_info'
      }
    }
  },
  computed: {
    paramsMap() {
      return {
        ...this.params,
        pageNumber: '1',
        pageSize: '5'
      }
    }
  },
  created() {
  },
  methods: {
    handleShow() {
      if (this.beforeSearch()) {
        this.dialogVisible = true
        if (this.$refs.queryTable && this.$refs.queryTable.handleSearch) {
          this.$refs.queryTable.handleSearch()
        }
      }
    },

    handleSelected(row) {
      console.log(row)
      this.dialogVisible = false
      this.$emit('input', row.interbankno)
      this.$emit('selected', row)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
