<!--
 * @Author: 段海兵
 * @Date: 2022-11-07 17:29:36
 * @LastEditors: 段海兵
 * @Description: 缴纳保证金
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="bond">
    <!-- :disabled="disabled" -->
    <el-button class="bond-btn" type="success" :disabled="disabled" @click="handleBond"><slot /></el-button>
    <el-dialog
      class="bond-dialog"
      title="缴纳竞买保证金"
      width="450px"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <el-form ref="bondForm" :model="form" status-icon :rules="rules" label-width="60px">
        <!-- <el-form-item class="no-margin" label="户名" prop="name">
          <span>北京登记结算有限公司</span>
        </el-form-item> -->
        <!-- <el-form-item class="no-margin" label="收款账号" prop="no">
          <span>0200291419100218401</span>
        </el-form-item> -->
        <!-- <el-form-item label="开户行" prop="bank">
          <span>中国工商银行股份有限公司北京金树街支行</span>
        </el-form-item> -->
        <el-form-item label="金额" prop="amount">
          <el-input v-model="form.amount" placeholder="金额" prefix-icon="el-icon-money" clearable readonly autocomplete="off"><span slot="append">元</span></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" class="w-100" :loading="loading" @click="handleBondSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApi } from '@/utils/request'
import { requiredRules, moneyRules } from '@/utils/validate'
import { fixNum } from '@/utils'
export default {
  name: 'Bond',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    bidEnrollInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        amount: ''
      },
      rules: {
        amount: [
          requiredRules('金额'),
          moneyRules('金额')
        ]
      },
      loading: false
    }
  },
  computed: {},
  created() {},
  methods: {
    initData(info) {
      this.form = {
        amount: info.appsheetserialno ? (info.applicationvol * info.tradingprice + Number(info.charge)).toFixed(2) : ''
      }
    },
    handleBond() {
      if (this.$refs.bondForm && this.$refs.bondForm.resetFields) {
        this.$refs.bondForm.resetFields()
      }
      this.initData(this.bidEnrollInfo)
      this.dialogVisible = true
    },
    handleBondSubmit() {
      this.$refs.bondForm.validate((valid) => {
        if (valid) {
          this.$confirm(`请确认缴纳竞买保证金<span class="cl-orange font-medium mr-50 ml-50">${fixNum(this.form.amount, 2)}元</span>，提交成功后将不能撤销和修改！是否继续提交？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(() => {
            this.loading = true
            getApi('kingdom.kfts.uc_12001_apply_bj_djjs', 'V1.0', {
              ...this.form,
              lotid: this.bidEnrollInfo.lotid
            }).then((kdjson) => {
              this.loading = false
              const flag = kdjson.flag
              const items = kdjson.items
              if (flag === '1' && items) {
                this.dialogVisible = false
                window.open(items[0].cashierUrl)
                // 刷新资金信息
                // this.$emit('bonded')
                // this.$message({
                //   message: kdjson.msg,
                //   type: 'success'
                // })
              } else {
                this.$message({
                  message: kdjson.msg || '缴纳失败！',
                  type: 'error'
                })
              }
            }).catch((kdjson) => {
              this.loading = false
              this.$message({
                message: kdjson.msg,
                type: 'error'
              })
            })
          }).catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bond{
  width: 100%;
  height: 100%;
  .bond-btn {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: .75rem 1.25rem;
  }
}

.bond-dialog {
  .no-margin {
    margin-bottom: 0;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
