<template>
  <div class="app-content flex direction-column">
    <div class="head flex">
      <div class="head-left flex-1">
        <div class="base-info flex y-center">
          <div class="list flex-1">
            <div class="list-title cl-white font-medium"> {{ signTime }} <span class="sub-title" /></div>
            <div class="list-items">
              <div class="list-item">
                <label class="item-label">产品代码</label>
                <div class="item-value cl-white font-medium text-ellipsis">
                  {{ bidInfo.fundcode }}
                </div>
              </div>
              <div class="list-item long">
                <label class="item-label">产品名称</label>
                <div class="item-value cl-white font-medium text-ellipsis">
                  {{ bidInfo.fundname }}
                </div>
              </div>
            </div>
            <div class="list-foot flex y-center">
              <label class="cl-white font-medium">
                <!-- {{ countDown.status }} -->
                <!-- <el-tag type="warning" effect="dark">{{ bidInfo.applystatusname }}</el-tag> -->
              </label>
              <div class="foot-content flex-1 flex y-center x-center">
                <div class="status flex y-end cl-white">{{ countDown.status }}</div>
                <div v-for="(n, i) in getNumber(countDown.d, 3)" :key="`d-${i}`" :class="`time-item num-${n}`" />
                <div class="time-item day" />
                <div v-for="(n, i) in getNumber(countDown.h)" :key="`h-${i}`" :class="`time-item num-${n}`" />
                <div class="time-item hour" />
                <div v-for="(n, i) in getNumber(countDown.m)" :key="`m-${i}`" :class="`time-item num-${n}`" />
                <div class="time-item minute" />
                <div v-for="(n, i) in getNumber(countDown.s)" :key="`s-${i}`" :class="`time-item num-${n}`" />
                <div class="time-item second" />
              </div>
            </div>
          </div>
          <div class="operator flex direction-column space-between">
            <div class="operator-item">
              <sign :bid-info="bidInfo" :disabled="!signStatus" @enrolled="refreshEnrollInfo">竞价申报</sign>
            </div>
            <div class="operator-item">
              <bond :disabled="!bondStatus" :bid-enroll-info="bidEnrollInfo" @bonded="getMoneyInfo">缴纳竞买保证金</bond>
            </div>
            <div class="operator-item">
              <el-button class="come-to-bid-home" type="primary" :disabled="!isBidHomeBtn" @click="jumpToBidScreen()">进入竞价大厅</el-button>
            </div>
          </div>
          <!-- <span class="base-info-tips cl-white">
            {{ signTime }}
          </span> -->
        </div>
        <div class="bid-items bg-white flex space-between orderly">
          <div class="bid-item flex y-center">
            <div class="info flex-1">
              <label class="item-label">发放总量(吨)</label>
              <div class="item-value font-medium text-ellipsis">
                {{ bidInfo.applicationvol | fixNum }}
              </div>
            </div>
            <div class="icon circle t-c">
              <i class="el-icon-s-operation" />
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="bid-item flex y-center long">
            <div class="info flex-1">
              <label class="item-label">申报竞买量</label>
              <div class="item-value font-medium" v-html="buyVolTips" />
            </div>
            <div class="icon circle t-c">
              <i class="el-icon-warning-outline" />
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="bid-item flex y-center">
            <div class="info flex-1">
              <label class="item-label">竞价底价(元/吨)</label>
              <div class="item-value font-medium text-ellipsis">
                {{ bidInfo.reserveprice | fixNum(2) }}
              </div>
            </div>
            <div class="icon circle t-c">
              <i class="el-icon-data-line" />
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="bid-item flex y-center">
            <div class="info flex-1">
              <label class="item-label">成交方式</label>
              <div class="item-value font-medium text-ellipsis">
                <!-- {{ bidInfo.dealtypename }} --> 单轮竞价
              </div>
            </div>
            <div class="icon circle t-c">
              <i class="el-icon-position" />
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="bid-item flex y-center middle">
            <div class="info flex-1">
              <label class="item-label">竞价时间</label>
              <div class="item-value font-medium" v-html="bidTime" />
            </div>
            <div class="icon circle t-c">
              <i class="el-icon-stopwatch" />
            </div>
          </div>
        </div>
      </div>
      <div class="user-info bg-white">
        <div class="info-title flex y-center">
          <div class="icon cl-white circle bg-green t-c">
            <i class="el-icon-user-solid" />
          </div>
          <div v-if="!!loginName" class="info flex-1">
            <div class="name font-bold">{{ loginName }}</div>
            <div class="phone">{{ accountInfo.name }}</div>
          </div>
          <div v-else class="info flex-1 cl-grey">
            暂未登录，<el-button type="text" @click="handleLogin">去登录</el-button>
          </div>
        </div>
        <div class="info-item flex y-center">
          <div class="flex-1 text-ellipsis">
            <div class="item-value font-medium text-ellipsis">{{ moneyInfo.zjye || 0 | fixNum(2) }}</div>
            <label class="item-lable">资金余额（元）</label>
          </div>
          <el-button-group v-if="!!loginName">
            <el-tooltip popper-class="tooltip-transparent" effect="dark" content="出金" placement="bottom">
              <el-button type="warning" :disabled="!withdrawStatus" class="withdraw-btn" size="mini" round @withdrawed="getMoneyInfo" @click="handleWithdraw">出金</el-button>
            </el-tooltip>
            <el-tooltip popper-class="tooltip-transparent" effect="dark" content="出入金记录" placement="bottom">
              <el-button class="record-btn" size="mini" type="info" round @click="handleMoneyRecord">查询</el-button>
            </el-tooltip>
          </el-button-group>
        </div>
        <div class="info-item">
          <div class="item-value font-medium text-ellipsis">{{ moneyInfo.feye || 0 | fixNum }}</div>
          <label class="item-lable">竞价所得配额（吨）</label>
        </div>
        <div class="info-item ">
          <!-- 0,待审核 1,待缴纳保证金 3,报名成功 2,审核未通过 9,已失效 -->
          <div v-if="!loginName" class="item-value font-medium text-ellipsis">-</div>
          <div v-else class="item-value font-medium text-ellipsis">{{ bidStatus }}</div>
          <label class="item-lable">竞价状态</label>
        </div>
      </div>
    </div>
    <div class="body flex-1 flex space-between">
      <el-card class="box-card" header="交易费用" shadow="never">
        <!-- <div class="fee-item sell">
          <div class="title">卖方</div>
          <div class="details flex">
            <div class="details-item">
              <label>计费策略</label>
              <div class="value text-ellipsis medium">{{ bidInfo.sellfeepolicyname }}</div>
            </div>
            <div class="details-item">
              <label>计费目标</label>
              <div class="value text-ellipsis medium">{{ bidInfo.sellobjectname }}</div>
            </div>
            <div class="details-item">
              <label>费率%</label>
              <div class="value text-ellipsis medium">{{ bidInfo.sellfixfee | formatPercent }}</div>
            </div>
          </div>
        </div> -->
        <div class="fee-item buy">
          <div class="title">手续费</div>
          <div class="details flex">
            <div class="details-item">
              <label>计费策略</label>
              <div class="value text-ellipsis medium">{{ bidInfo.buyfeepolicyname }}</div>
            </div>
            <div class="details-item">
              <label>计费目标</label>
              <div class="value text-ellipsis medium">{{ bidInfo.buyobjectname }}</div>
            </div>
            <div class="details-item">
              <label>费率‰</label>
              <div class="value text-ellipsis medium">{{ bidInfo.buyfixfee | formatMicrometer }}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card" header="通知公告" shadow="never">
        <template v-if="!!noticeInfo.length">
          <div v-for="item in noticeInfo" :key="item.noticeid" class="notice-item" @click="handleSeeNotice(item)">
            <div class="title medium text-ellipsis">
              <i class="el-icon-chat-line-square" />
              {{ item.noticeabstract }}
            </div>
            <div class="date">{{ item.publishdate | formatDate }}</div>
          </div>
        </template>
        <el-empty v-else description="暂无公告信息" />
      </el-card>
      <el-card class="box-card" header="我的记录" shadow="never">
        <div v-if="bidEnrollInfo.appsheetserialno" class="records">
          <div class="record-item apply">
            <div class="title flex"><span class="flex-1">申报信息</span><div class="sub-title">{{ bidEnrollInfo.transactiondate | formatDate }} {{ bidEnrollInfo.transactiontime | formatTime }}</div></div>
            <div class="content flex wrap">
              <div class="content-item">
                <div class="label">竞买价格</div>
                <div class="value">{{ bidEnrollInfo.tradingprice | fixNum(2) }}元/吨</div>
              </div>
              <div class="content-item">
                <div class="label">竞买数量</div>
                <div class="value">{{ bidEnrollInfo.applicationvol | fixNum }}吨</div>
              </div>
              <div class="content-item">
                <div class="label">手续费</div>
                <div class="value">{{ bidEnrollInfo.charge | fixNum(2) }}元</div>
              </div>
              <div class="content-item">
                <div class="label">竞买保证金</div>
                <div class="value">{{ (bidEnrollInfo.applicationvol*bidEnrollInfo.tradingprice + Number(bidEnrollInfo.charge)) | fixNum(2) }}元</div>
              </div>
            </div>
          </div>
          <div v-if="bidEnrollInfo.status === '3'" class="record-item deal">
            <div class="title flex"><span class="flex-1">成交信息</span><div class="sub-title"><print :bid-info="bidInfo" :bid-enroll-info="bidEnrollInfo">凭证打印</print></div></div>
            <div class="content flex wrap">
              <div class="content-item">
                <div class="label">成交数量</div>
                <div class="value">{{ bidEnrollInfo.dealvol | fixNum }}吨</div>
              </div>
              <div class="content-item">
                <div class="label">成交价格</div>
                <div class="value">{{ bidEnrollInfo.dealprice | fixNum(2) }}元</div>
              </div>
              <div class="content-item">
                <div class="label">成交金额</div>
                <div class="value">{{ bidEnrollInfo.dealamount | fixNum(2) }}元</div>
              </div>
              <div class="content-item">
                <div class="label">成交手续费</div>
                <div class="value">{{ bidEnrollInfo.totalcharge | fixNum(2) }}元</div>
              </div>
            </div>
            <div
              v-if="bidEnrollInfo.status"
              :class="{
                'records-status': true,
                success: true
              }"
            >
              <div class="flex x-center y-center">竞价成功</div>
            </div>
          </div>
        </div>
        <div v-else class="records">
          <div class="record-item apply">
            <div class="title flex"><span class="flex-1">报名信息</span></div>
            <div class="content flex wrap">
              <div class="content-item">
                <div class="label">竞买价格</div>
                <div class="value">-</div>
              </div>
              <div class="content-item">
                <div class="label">竞买数量</div>
                <div class="value">-</div>
              </div>
              <div class="content-item">
                <div class="label">手续费</div>
                <div class="value">-</div>
              </div>
              <div class="content-item">
                <div class="label">竞买保证金</div>
                <div class="value">-</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 查看公告 -->
    <notice ref="noticeModal" />

    <!-- 出金 -->
    <withdraw ref="withdrawModal" :money-info="moneyInfo" />
    <!-- 出入金记录 -->
    <money-record ref="moneyRecordModal" @retryed="getMoneyInfo" />
  </div>
</template>

<script>
import { toDate, getUrlParams } from '@/utils'
import { getApi } from '@/utils/request'
import { mapState } from 'vuex'
import Sign from './sign'
import Bond from './bond'
import Withdraw from './withdraw'
import MoneyRecord from './money-record'
import Notice from './notice'
import Print from './print'
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
let refreshTimer = null

const weekMaps = { 0: '星期日', 1: '星期一', 2: '星期二', 3: '星期三', 4: '星期四', 5: '星期五', 6: '星期六' }
export default {
  name: 'Home',
  components: {
    Sign,
    Bond,
    Withdraw,
    MoneyRecord,
    Notice,
    Print
  },
  data() {
    return {
      bidTimer: null,
      // 标的信息
      bidInfo: {},
      // 资金信息
      moneyInfo: {},
      // 标的报名信息
      bidEnrollInfo: {},
      // 公告信息
      noticeInfo: [],
      // 处理成功的入金记录
      bondedRecords: []
    }
  },
  computed: mapState({
    // 申报竞买量提示
    buyVolTips() {
      return setting.buyVolTips
    },
    // 登录名
    loginName: state => state.account.loginName,
    // 账户信息
    accountInfo: state => state.account.accountInfo,
    // 服务器时间
    systemTime: state => state.system.time,
    // 倒计时
    countDown() {
      if (this.bidInfo.lotid && ['01', '02', '06', '07'].includes(this.bidInfo.applystatus)) {
        const systemTime = this.systemTime.getTime()
        const startSignTime = toDate(this.bidInfo.enterstartdate + this.bidInfo.enterstarttime).getTime()
        const endSignTime = toDate(this.bidInfo.enterenddate + this.bidInfo.enterendtime).getTime()
        const startBidTime = toDate(this.bidInfo.bidstartdate + this.bidInfo.bidstarttime).getTime()
        const endBidTime = toDate(this.bidInfo.bidenddate + this.bidInfo.bidendtime).getTime()
        let time = 0
        let status = ''
        if (startSignTime > systemTime) {
          // 报名未开始
          time = startSignTime - systemTime
          status = '距离报名开始还剩'
        } else if (systemTime < endSignTime) {
          // 报名中
          time = endSignTime - systemTime
          status = '距离报名结束还剩'
        } else if (startBidTime > systemTime) {
          // 竞价未开始
          time = startBidTime - systemTime
          status = '距离竞价开始还剩'
        } else if (systemTime < endBidTime) {
          // 竞价中
          time = endBidTime - systemTime
          status = '距离竞价结束还剩'
        } else {
          // 竞价结束
          time = 0
        }
        const d = parseInt(time / (24 * 60 * 60 * 1000))
        const h = parseInt((time % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
        const m = parseInt((time % (60 * 60 * 1000)) / (60 * 1000))
        const s = parseInt((time % (60 * 1000)) / 1000)
        return {
          d,
          h,
          m,
          s,
          status
        }
      } else {
        if (this.bidTimer) {
          clearInterval(this.bidTimer)
        }
      }
      return {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
        status: ''
      }
    },
    // 竞价申报时间提示
    signTime() {
      const signStartTime = this.bidInfo.enterstartdate + this.bidInfo.enterstarttime
      const signEndTime = this.bidInfo.enterenddate + this.bidInfo.enterendtime
      if (signStartTime && signEndTime) {
        const sDate = toDate(signStartTime)
        const syear = sDate.format('yyyy')
        const smonth = sDate.format('MM')
        const sday = sDate.format('dd')
        const eDate = toDate(signEndTime)
        const eyear = eDate.format('yyyy')
        const emonth = eDate.format('MM')
        const eday = eDate.format('dd')
        // console.log(sDate.getDay())
        if (syear === eyear) {
          if (smonth === emonth) {
            if (sday === eday) {
              return `报名期为${sDate.format('yyyy年M月d日')}（${weekMaps[eDate.getDay()]}）${sDate.format('h:mm')}-${eDate.format('h:mm')}，法定节假日除外`
            } else {
              return `报名期为${sDate.format('yyyy年M月d日')}至${eDate.format('d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}，法定节假日除外`
            }
          } else {
            return `报名期为${sDate.format('yyyy年M月d日')}至${eDate.format('M月d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}，法定节假日除外`
          }
        } else {
          return `报名期为${sDate.format('yyyy年M月d日')}至${eDate.format('yyyy年M月d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}，法定节假日除外`
        }
      }
      // return '报名期为2022年11月15日至21日9:30-17:00，法定节假日除外。'
    },
    // 竞价时间提示
    bidTime() {
      const bidStartTime = this.bidInfo.bidstartdate + this.bidInfo.bidstarttime
      const bidEndTime = this.bidInfo.bidenddate + this.bidInfo.bidendtime
      if (bidStartTime && bidEndTime) {
        const sDate = toDate(bidStartTime)
        const syear = sDate.format('yyyy')
        const smonth = sDate.format('MM')
        const sday = sDate.format('dd')
        const eDate = toDate(bidEndTime)
        const eyear = eDate.format('yyyy')
        const emonth = eDate.format('MM')
        const eday = eDate.format('dd')
        // console.log(sDate.getDay())
        if (syear === eyear) {
          if (smonth === emonth) {
            if (sday === eday) {
              return `${sDate.format('yyyy年M月d日')}（${weekMaps[eDate.getDay()]}）${sDate.format('h:mm')}-${eDate.format('h:mm')}`
            } else {
              return `${sDate.format('yyyy年M月d日')}至${eDate.format('d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}`
            }
          } else {
            return `${sDate.format('yyyy年M月d日')}至${eDate.format('M月d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}`
          }
        } else {
          return `${sDate.format('yyyy年M月d日')}至${eDate.format('yyyy年M月d日')} ${sDate.format('h:mm')}-${eDate.format('h:mm')}`
        }
      }
      // return '2022年11月23日（周三）9:30-10:00'
    },
    // 竞价申报按钮是否可点击
    signStatus() {
      return this.bidInfo.applystatus === '06' && !this.bidEnrollInfo.appsheetserialno
    },
    // 进入竞价大厅按钮是否可点击
    isBidHomeBtn() {
      // console.log(this.systemTime, new Date(setting.bidHomeSplitTime), this.systemTime.getTime() >= (new Date(setting.bidHomeSplitTime)).getTime())
      return this.systemTime.getTime() >= (new Date(setting.bidHomeSplitTime)).getTime()
    },
    // 出金按钮是否可点击
    withdrawStatus() {
      return !!Number(this.moneyInfo.zjky) && this.systemTime.getTime() >= (new Date(setting.withdrawSplitTime)).getTime()
    },
    // 缴纳保证金按钮是否可点击
    bondStatus() {
      // const startBidTime = toDate(this.bidInfo.bidstartdate + this.bidInfo.bidstarttime).getTime()
      // return this.bidStatus === '待缴纳保证金' && !this.bondedRecords.length && this.systemTime.getTime() < startBidTime
      return setting.payMoneyStatus
    },
    // 竞价状态
    bidStatus() {
      // bidEnrollInfo.appsheetserialno ? bidEnrollInfo.checkstatusname : '待申报'
      if (this.bidEnrollInfo.appsheetserialno) {
        const money = (this.bidEnrollInfo.applicationvol * this.bidEnrollInfo.tradingprice + Number(this.bidEnrollInfo.charge)).toFixed(2)
        const zjye = Number(this.moneyInfo.zjye).toFixed(2)
        const enterEndTime = toDate(this.bidInfo.enterenddate + this.bidInfo.enterendtime).getTime()
        if (this.systemTime.getTime() >= (new Date(setting.bidStatusSplitTime)).getTime()) {
          if (Number(zjye) >= Number(money)) {
            if (this.bidEnrollInfo.auditstatus === '0') {
              return '待审核'
            } else if (this.bidEnrollInfo.auditstatus === '1') {
              return '审核通过'
            } else if (this.bidEnrollInfo.auditstatus === '2') {
              return '审核不通过'
            }
            return this.bidEnrollInfo.auditstatusname
          } else if (this.bidEnrollInfo.status && this.systemTime.getTime() > enterEndTime) {
            return '审核通过'
          }
          return '待缴纳保证金'
        } else {
          if (Number(zjye) >= Number(money)) {
            return '待审核'
          }
          return '待缴纳保证金'
        }
      }
      return '待申报'
    }
  }),
  created() {
    this.refreshBidInfo()
    if (refreshTimer) clearInterval(refreshTimer)
    refreshTimer = setInterval(() => {
      console.log('refresh')
      this.refreshBidInfo()
    }, setting.bidRefreshTime ? setting.bidRefreshTime * 1000 : 10000)
    document.addEventListener('visibilitychange', () => {
      if (document.hidden === true) {
        // 页面被挂起
      } else {
        // 页面由挂起被激活
        this.refreshBidInfo()
      }
    })

    this.getNotice({
      tradeflag: ''
    })
    this.$bus.$on('logined', () => {
      this.getBondedRecords()
      if (this.bidInfo.lotid) {
        this.getMoneyInfo()
        // 需要同时满足标的信息接口返回 且 已登录才能调用
        this.getBidEnrollInfo({
          lotid: this.bidInfo.lotid
        })
      }
    })
    this.$bus.$on('logouted', () => {
      this.moneyInfo = {}
      this.bidEnrollInfo = {}
      this.bondedRecords = []
    })
  },
  destroyed() {
    if (this.bidTimer) {
      clearInterval(this.bidTimer)
    }
  },
  methods: {
    jumpToBidScreen() {
      this.$router.push({
        name: 'Screen'
      })
    },
    getNumber(n, fix = 2) {
      const num = `0000000000000000${n}`
      return num.substring(num.length - fix, num.length)
    },
    // 点击登录按钮
    handleLogin() {
      // 弹出登录框
      this.$bus.$emit('login.show')
    },
    // 刷新标的信息
    refreshBidInfo() {
      this.getBidInfo({
        lotid: getUrlParams('lotid') || ''
      }).then(() => {
        if (this.loginName) {
          // 需要同时满足标的信息接口返回 且 已登录才能调用
          this.getBidEnrollInfo({
            lotid: this.bidInfo.lotid || ''
          })
          this.getMoneyInfo()
        }
      })
    },
    // 刷新报名信息
    refreshEnrollInfo() {
      this.getBidEnrollInfo({
        lotid: this.bidInfo.lotid || ''
      })
    },
    // 出金
    handleWithdraw() {
      this.$refs.withdrawModal.handleShow()
    },
    // 出入金记录
    handleMoneyRecord() {
      this.$refs.moneyRecordModal.handleShow()
    },
    // 查看公告
    handleSeeNotice(item) {
      this.$refs.noticeModal.handleShow(item)
    },
    // 获取标的信息
    getBidInfo(params = {}) {
      return new Promise((resolve, reject) => {
        getApi('kingdom.kfts.client_get_lot_info_bj', 'V1.0', { ...params })
          .then((kdjson) => {
            const flag = kdjson.flag
            const items = kdjson.items
            if (flag === '1' && items) {
              this.bidInfo = items[0]
              resolve(items[0])
            } else if (flag === '1') {
              this.bidInfo = {}
              reject()
            } else {
              this.bidInfo = {}
              reject()
              this.$message({
                message: kdjson.msg || '未获取到标的信息！',
                type: 'error'
              })
            }
          })
          .catch((kdjson) => {
            this.bidInfo = {}
            reject()
            this.$message({
              message: kdjson.msg,
              type: 'error'
            })
          })
      })
    },
    // 获取标的资金信息
    getMoneyInfo(params = {}) {
      getApi('kingdom.kfts.client_get_user_assets_bj', 'V1.0', {
        ...params,
        fundcode: this.bidInfo.fundcode
      })
        .then((kdjson) => {
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            this.moneyInfo = items[0]
          } else {
            this.moneyInfo = {}
          }
        })
        .catch((kdjson) => {
          this.moneyInfo = {}
          this.$message({
            message: kdjson.msg,
            type: 'error'
          })
        })
    },
    // 获取标的报名信息
    getBidEnrollInfo(params = {}) {
      getApi('kingdom.kfts.client_get_user_qualified_apply_bj', 'V1.0', { ...params })
        .then((kdjson) => {
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            this.bidEnrollInfo = items[0]
          } else {
            this.bidEnrollInfo = {}
          }
        })
        .catch((kdjson) => {
          this.bidEnrollInfo = {}
          this.$message({
            message: kdjson.msg,
            type: 'error'
          })
        })
    },
    // 获取公告信息
    getNotice(params = {}) {
      getApi('kingdom.kfts.get_out_notice_list', 'V1.0', {
        ...params,
        pageNumber: '1',
        customerno: '',
        noticeabstract: '',
        pageSize: '99999',
        markcode: '0'
      }).then((kdjson) => {
        const flag = kdjson.flag
        const items = kdjson.items
        if (flag === '1' && items) {
          this.noticeInfo = items
        } else {
          this.noticeInfo = []
        }
      }).catch((kdjson) => {
        this.noticeInfo = []
        this.$message({
          message: kdjson.msg,
          type: 'error'
        })
      })
    },
    // 获取处理成功的入金记录
    getBondedRecords(params = {}) {
      getApi('kingdom.kfts.get_pay_apply_info', 'V1.0', {
        ...params,
        // 0 已接受 1 处理中 2 处理成功 3 处理失败
        status: '2',
        direction: '0',
        pageNumber: '1',
        pageSize: '99999'
      }).then((kdjson) => {
        const flag = kdjson.flag
        const items = kdjson.items
        if (flag === '1' && items) {
          this.bondedRecords = items
        } else {
          this.bondedRecords = []
        }
      }).catch((kdjson) => {
        this.bondedRecords = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-content {
  height: 100%;
  .head {
    height: 23rem;
    .head-left {
      margin-right: 1rem;
      .base-info {
        position: relative;
        height: 13.25rem;
        padding: 1rem 1.5rem 0.5rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        background-color: rgba(255, 255, 255, 0.15);
        // .base-info-tips {
        //   position: absolute;
        //   right: 1.5rem;
        //   bottom: 0.5rem;
        // }
        .come-to-bid-home {
          width: 100%;
          height: 100%;
          font-size: 1rem;
          border-radius: 0.5rem;
        }

        .list {
          height: 11.75rem;
          font-size: 1.125rem;
          // padding-top: 1.75rem;
          .list-title {
            width: 100%;
            height: 1.625rem;
            font-size: 1.25rem;
            line-height: 1.625rem;
            margin-bottom: 0.75rem;
            color: #daf3e4;
            .sub-title {
              font-size: .875rem;
              line-height: 1rem;
              color: #fafefb;
              padding: .625rem 1rem 0;
            }
          }
          .list-items {
            display: flex;
            width: 100%;
            margin-bottom: 0.75rem;
            .list-item {
              width: 31%;
              height: 1.75rem;
              line-height: 1.75rem;
              display: flex;
              &.long {
                width: 66%;
              }
              .item-label {
                color: rgba(255, 255, 255, 0.7);
              }
              .item-value {
                padding: 0 0.75rem;
                flex: 1;
                font-size: 1.25rem;
              }
            }
          }
          .list-foot {
            height: 6.875rem;
            label {
              font-size: 1rem;
              ::v-deep {
                .el-tag{
                  font-size: 1.375rem;
                  padding: 0 1rem;
                  height: auto;
                  line-height: 2.5rem;
                  margin-right: 0.5rem;
                  vertical-align: bottom;
                }
              }
            }
            .foot-content {
              .status {
                height: 46px;
                font-size: 0.825rem;
                line-height: 1.5rem;
                margin-right: 0.5rem;
              }
              .time-item {
                width: 32px;
                height: 46px;
                background-image: url("~@/assets/images/times.png");
                background-repeat: no-repeat;
                &.day {
                  width: 40px;
                  background-position: -5px -50px;
                }
                &.hour {
                  width: 40px;
                  background-position: -60px -50px;
                }
                &.minute {
                  width: 40px;
                  background-position: -116px -50px;
                }
                &.second {
                  width: 40px;
                  background-position: -175px -50px;
                }
                &.num-0 {
                  background-position: -3px 0px;
                }
                &.num-1 {
                  background-position: -44px 0px;
                }
                &.num-2 {
                  background-position: -83px 0px;
                }
                &.num-3 {
                  background-position: -125px 0px;
                }
                &.num-4 {
                  background-position: -170px 0px;
                }
                &.num-5 {
                  background-position: -213px 0px;
                }
                &.num-6 {
                  background-position: -258px 0px;
                }
                &.num-7 {
                  background-position: -300px 0px;
                }
                &.num-8 {
                  background-position: -344px 0px;
                }
                &.num-9 {
                  background-position: -388px 0px;
                }
              }
            }
          }
        }

        .operator {
          width: 10rem;
          height: 10rem;
          .operator-item {
            height: 3rem;
            .come-to-bid-home {
              padding: .75rem 1.25rem;
            }
          }
        }
      }
      .bid-items {
        height: 8.75rem;
        padding: 1.5rem 2.5rem;
        border-radius: 0.5rem;
        &.orderly {
          .item-value {
            height: 3.25rem;
          }
        }
        .bid-item {
          width: 15%;
          &.long {
            width: 35%;
            .item-value {
              font-size: 0.9rem;
              max-width: 98%;
            }
          }
          &.middle {
            width: 21%;
            .item-value {
              font-size: 1rem;
            }
          }
          height: 5.75rem;
          .item-label {
            font-size: 0.875rem;
            line-height: 1.375rem;
            color: #3d434a;
          }
          .item-value {
            color: #1f2329;
            font-size: 1.125rem;
            line-height: 1.625rem;
            margin-top: 0.5rem;
          }
          .icon {
            width: 3.75rem;
            height: 3.75rem;
            background-color: rgba(103, 194, 58, 0.08);
            font-size: 1.875rem;
            line-height: 3.75rem;
            color: #ff6e66;
          }
        }

        ::v-deep {
          .el-divider {
            height: 100%;
            background-color: #f6f7f8;
          }
        }
      }
    }
    .user-info {
      width: 18.875rem;
      height: 100%;
      padding: 0.75rem 1.5rem 1.75rem;
      border-radius: 0.5rem;

      .info-title {
        width: 100%;
        height: 4.5rem;
        // padding-top: 0.75rem;
        margin-bottom: 1rem;
        .icon {
          width: 3rem;
          height: 3rem;
          font-size: 1.375rem;
          line-height: 3rem;
        }
        .info {
          padding-left: 0.75rem;
          .name {
            height: 1.5rem;
            line-height: 1.5rem;
            font-size: 0.875rem;
            color: #1f2329;
          }
          .phone {
            min-height: 1.25rem;
            font-size: 0.75rem;
            color: #3d434a;
            line-height: 1.25rem;
          }
        }
      }
      .info-item {
        height: 4rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: #f5f8fa;
        margin-bottom: 1rem;
        .withdraw-btn {
          padding: 0 .5rem 0 .75rem;
        }
        .record-btn {
          padding: 0 .75rem 0 .5rem;
        }
        .item-value {
          color: #1f2329;
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
        .item-lable {
          color: rgba(0, 0, 0, 0.56);
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        ::v-deep {
          .el-button {
            font-size: .75rem;
            line-height: .75rem;
            height: 1.75rem;
          }
        }
      }
    }
  }
  .body {
    height: calc(100% - 23rem);
    min-height: 23rem;
    padding: 1rem 0 0;

    .box-card {
      width: calc(100% / 3 - 0.6875rem);
      height: 100%;
      overflow: hidden;
      padding: 1rem 1.5rem;
      border-radius: 0.375rem;
      border: none;
      ::v-deep {
        .el-card__header {
          padding: 0px 0px 1rem;
          color: #3d434a;
          font-size: 1rem;
          line-height: 1.5rem;
          border: none;
        }
        .el-card__body {
          position: relative;
          height: calc(100% - 2.5rem);
          padding: 0;

          .fee-item {
            height: 7.625rem;
            padding: 1rem;
            border-radius: 0.5rem;
            &.sell {
              margin-bottom: 1rem;
              background: linear-gradient(158deg, #f0ffef 0%, #c1ffc4 100%);
            }
            &.buy {
              background: linear-gradient(142deg, #fdf7f1 0%, #ffdfd6 99%);
            }
            .title {
              height: 2.25rem;
              font-size: 1rem;
              line-height: 1.5rem;
            }
            .details-item {
              width: 33.3%;
              height: 3.375rem;
              label {
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: rgba(0, 0, 0, 0.2);
              }
              .value {
                width: 100%;
                height: 2rem;
                padding-top: 0.5rem;
                font-size: 1rem;
                line-height: 1.5rem;
              }
            }
          }
          .notice-item {
            margin-bottom: 1rem;
            cursor: pointer;
            .el-divider {
              margin: 0.5rem 0;
              background-color: #f6f7f8;
              .el-divider__text {
                font-size: 0.875rem;
                color: #c0c4cc;
              }
            }
            .title {
              height: 2rem;
              padding: 0.5rem 0 0.5rem;
              font-size: 1rem;
              line-height: 1.5rem;
              i {
                color: #67c23a;
                margin-right: 0.9375rem;
              }
            }
            .date {
              height: 1.375rem;
              padding-left: 2.125rem;
              font-size: 0.875rem;
              line-height: 1.375rem;
              color: #606266;
              margin-bottom: 0.25rem;
            }
            .simple {
              width: 100%;
              height: 4.5rem;
              padding-left: 2.125rem;
              font-size: 1rem;
              line-height: 1.5rem;
              white-space: normal;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .records-time{
            font-size: .75rem;
          }
          .records {
            .records-status{
              position: absolute;
              right: 2rem;
              top: 3.5rem;

              width: 6rem;
              height: 6rem;
              padding: 0.5rem;
              border: 2px solid rgba(19, 178, 197, 0.8);
              border-radius: 50%;
              div{
                width: 100%;
                height: 100%;
                border: 1px dotted rgba(19, 178, 197, 0.8);
                border-radius: 50%;
                color: rgba(19, 178, 197, 0.8);
              }

              &.success {
                border-color: rgba(134, 206, 97, 0.8);
                div {
                  border-color: rgba(134, 206, 97, 0.8);
                  color: rgba(134, 206, 97, 0.8);
                }
              }
              &.error {
                border-color: rgba(206, 119, 97, 0.8);
                div {
                  border-color: rgba(206, 119, 97, 0.8);
                  color: rgba(206, 119, 97, 0.8);
                }
              }
              &.info {
                border-color: rgba(100, 100, 100, 0.6);
                div {
                  border-color: rgba(100, 100, 100, 0.6);
                  color: rgba(100, 100, 100, 0.6);
                }
              }
            }
            .record-item {
              position: relative;
              height: 10.45rem;
              padding: 1rem;
              border-radius: 0.5rem;
              background: linear-gradient(180deg, #F7F7F7 0%, #FeFeFe 100%);
              &.apply {
                margin-bottom: .5rem;
              }
              .title {
                height: 2rem;
                span {
                  font-size: 1rem;
                  line-height: 1.25rem;
                }
                .sub-title {
                  font-size: .875rem;
                  color: #A9B2BE;
                }
              }
              .content{
                width: 100%;
                .content-item {
                  height: 3.375rem;
                  width: 50%;
                  .label {
                    font-size: 0.875rem;
                    line-height: 1.375rem;
                    color: rgba(0, 0, 0, 0.5);
                  }
                  .value {
                    width: 100%;
                    height: 2rem;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    padding: 0.1rem 0 0.5rem;
                  }
                }
              }
            }
          }

          overflow-y: auto;
          scrollbar-color: #dddddd #efefef; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
          scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
          -ms-overflow-style: none; /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 0.5rem; /*高宽分别对应横竖滚动条的尺寸*/
            height: 0.0625rem;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 0.25rem;
            background: #dddddd;
          }
          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            background: #efefef;
          }
        }
      }
    }
  }
}
</style>
