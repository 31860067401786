<template>
  <div class="sign">
    <el-button class="sign-btn" type="warning" :disabled="disabled" @click="handleSign"><slot /></el-button>

    <el-dialog
      class="sign-dialog"
      title="竞价申报"
      width="450px"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <div class="sign-alert">
        <el-alert
          title="竞买保证金总金额=申报数量*申报价格*（1+手续费率），其中手续费率为7.5‰"
          type="info"
          :closable="false"
        />
      </div>
      <el-form ref="signForm" :model="form" status-icon :rules="rules" label-width="130px">
        <el-form-item label="申报数量" required prop="applicationvol">
          <el-input v-model="form.applicationvol" placeholder="数量" prefix-icon="el-icon-coin" clearable autocomplete="off"><span slot="append">吨</span></el-input>
        </el-form-item>
        <el-form-item label="申报价格" required prop="tradingprice">
          <el-input v-model="form.tradingprice" placeholder="价格" prefix-icon="el-icon-money" clearable autocomplete="off"><span slot="append">元</span></el-input>
        </el-form-item>
        <el-form-item class="no-margin" label="手续费" prop="charge">
          <span v-if="!!form.charge">{{ form.charge | fixNum(2) }}元</span>
        </el-form-item>
        <el-form-item class="no-margin" label="竞买保证金总金额" prop="total">
          <span v-if="!!form.total">{{ form.total | fixNum(2) }}元</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" class="w-100" :loading="loading" @click="handleSignSubmit">立即申报</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getApi } from '@/utils/request'
// import kdes from '@/utils/kjax.des'
import { moneyRules, numRules } from '@/utils/validate'
import { fixNum } from '@/utils'
import { mapState } from 'vuex'
// import { MessageBox } from 'element-ui'
export default {
  name: 'Sign',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    bidInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const checkNum = (rule, value, callback) => {
      if (!value) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报数量不能为空`))
      } else if (value && !numRules('报名数量').pattern.test(value)) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(numRules('申报数量').message))
      } else if (Number(value) > Number(this.bidInfo.applicationvol)) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报数量不能超过发放总量${fixNum(this.bidInfo.applicationvol)}`))
      } else if (Number(value) > 300000) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报数量不能超过${fixNum(300000)}`))
      } else {
        callback()
      }
    }
    const checkPrice = (rule, value, callback) => {
      if (!value) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报价格不能为空`))
      } else if (value && !moneyRules('报名价格').pattern.test(value)) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(moneyRules('申报价格').message))
      } else if (Number(value) < Number(this.bidInfo.reserveprice)) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报价格不能低于${fixNum(this.bidInfo.reserveprice, 2)}`))
      } else if (Number(value) > 9999999999) {
        this.form.charge = ''
        this.form.total = ''
        callback(new Error(`申报价格不能高于${fixNum(9999999999, 2)}`))
      } else {
        callback()
      }
    }
    const checkCharge = (rule, value, callback) => {
      if (this.form.applicationvol && this.form.tradingprice) {
        getApi('kingdom.kfts.get_auction_quote_amount', 'V1.0', {
          lotid: this.bidInfo.lotid,
          applicationvol: this.form.applicationvol,
          tradingprice: this.form.tradingprice
        }).then((kdjson) => {
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            this.form.charge = items[0].charge
            this.form.total = `${this.form.applicationvol * this.form.tradingprice * 1.0075}`
            callback()
          } else {
            this.form.charge = ''
            this.form.total = ''
            callback(new Error('无法获取到手续费'))
          }
        }).catch(() => {
          this.form.charge = ''
          this.form.total = ''
          callback(new Error('无法获取到手续费'))
        })
      } else {
        this.form.charge = ''
        this.form.total = ''
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        applicationvol: '',
        tradingprice: '',
        charge: '',
        total: ''
      },
      rules: {
        applicationvol: [
          { validator: checkNum, trigger: ['change', 'blur'] },
          { validator: checkCharge, trigger: ['change', 'blur'] }
        ],
        tradingprice: [
          { validator: checkPrice, trigger: ['change', 'blur'] },
          { validator: checkCharge, trigger: ['change', 'blur'] }
        ]
      },
      loading: false
    }
  },
  computed: mapState({
    accountInfo: state => state.account.accountInfo
  }),
  created() {},
  methods: {
    handleSign() {
      if (this.$refs.signForm && this.$refs.signForm.resetFields) {
        this.$refs.signForm.resetFields()
      }
      this.dialogVisible = true
    },

    handleSignSubmit() {
      this.$refs.signForm.validate((valid) => {
        if (valid) {
          this.$confirm(`请确认申报数量<span class="cl-orange font-medium mr-50 ml-50">${fixNum(this.form.applicationvol)}吨</span>和申报价格<span class="cl-orange font-medium mr-50 ml-50">${fixNum(this.form.tradingprice, 2)}元</span>，提交成功后将不能撤销和修改！是否继续提交？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(() => {
            this.loading = true
            getApi('kingdom.kfts.set_out_req_qualified', 'V1.0', {
              ...this.form,
              lotid: this.bidInfo.lotid,
              fundcode: this.bidInfo.fundcode,
              // password: kdes.getDes(this.form.password),
              acceptmethod: '1',
              distributorcode: '0000',
              branchcode: '0000',
              transactionaccountid: '',
              password: ''
            }).then((kdjson) => {
              this.loading = false
              const flag = kdjson.flag
              if (flag === '1') {
                this.dialogVisible = false
                // 刷新标的信息
                this.$emit('enrolled')
                this.$message({
                  message: kdjson.msg,
                  type: 'success'
                })
              } else {
                this.$message({
                  message: kdjson.msg || '申报失败！',
                  type: 'error'
                })
              }
            }).catch((kdjson) => {
              this.loading = false
              this.$message({
                message: kdjson.msg,
                type: 'error'
              })
            })
          }).catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sign{
  width: 100%;
  height: 100%;
  .sign-btn {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: .75rem 1.25rem;
  }
}
.sign-dialog {
  .sign-alert{
    margin-bottom: 22px;
  }
  .no-margin {
    margin-bottom: 0;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 0 20px 20px;
    }
  }
  .label-long {
    ::v-deep {
      .el-form-item__label {
        line-height: 14px;
        height: 40px;
        padding-top: 6px;
      }
    }
  }
}
</style>
