<!--
 * @Author: 段海兵
 * @Date: 2022-11-08 16:09:07
 * @LastEditors: 段海兵
 * @Description: 表格查询组件-表格
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div v-loading="loading" class="search-list">
    <el-table :data="data" border style="width: 100%">
      <el-table-column v-for="item in colums" :key="item.name" :class="item.class" :fixed="item.fixed" :prop="item.name" :label="item.label" :width="item.width" :min-width="item.minWidth">
        <template slot-scope="{row}">
          <slot v-if="item.slot" :name="item.name" :data="row" />
          <span v-else-if="item.format === 'money'">{{ row[item.name] | fixNum(2) }}</span>
          <span v-else-if="item.format === 'number'">{{ row[item.name] | fixNum }}</span>
          <span v-else-if="item.format === 'date'">{{ row[item.name] | formatDate }}</span>
          <span v-else-if="item.format === 'time'">{{ row[item.name] | formatTime }}</span>
          <span v-else-if="item.format === 'datetime'">{{ row[item.name] | formatDateTime }}</span>
          <span v-else-if="item.format === 'percent'">{{ row[item.name] | formatPercent }}</span>
          <span v-else-if="item.format === 'micrometer'">{{ row[item.name] | formatMicrometer(2) }}</span>
          <span v-else>{{ item.render ? item.render(row[item.name], row) : row[item.name] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="search-list-page">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="pageNumber"
        :page-sizes="[5, 10, 20, 50, 100, 200]"
        :page-size="pageSize"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchList',
  props: {
    colums: {
      type: Array,
      default: () => ([])
    },
    data: {
      type: Array,
      default: () => ([])
    },
    params: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageSize: 10,
      pageNumber: 1
    }
  },
  computed: {
    total() {
      return Number(this.data.length ? this.data[0].ROWSCOUNT : 0)
    }
  },
  watch: {
    params(newValue) {
      this.pageSize = Number(newValue.pageSize || 10)
      this.pageNumber = Number(newValue.pageNumber || 10)
    }
  },
  created() {
    this.pageNumber = Number(this.params.pageNumber || 1)
    this.pageSize = Number(this.params.pageSize || 10)
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = 1
      this.pageSize = val
      this.$emit('page-change', {
        pageSize: `${this.pageSize}`,
        pageNumber: `${this.pageNumber}`
      })
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.$emit('page-change', {
        pageSize: `${this.pageSize}`,
        pageNumber: `${this.pageNumber}`
      })
    },
    resetPage() {
      this.pageNumber = 1
      this.pageSize = Number(this.params.pageSize || 10)
      this.$emit('page-change', {
        pageSize: `${this.pageSize}`,
        pageNumber: `${this.pageNumber}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .search-list-page{
    margin-top: 16px;
    ::v-deep {
      .el-pagination__sizes, .el-pagination__jump {
        .el-input__suffix {
          .el-input__icon {
            line-height: 28px;
            &.el-icon-circle-close {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
