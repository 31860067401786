<!--
 * @Author: 段海兵
 * @Date: 2022-10-27 13:35:26
 * @LastEditors: 段海兵
 * @Description: 查看出入金记录弹窗
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <el-dialog
    class="money-record-dialog"
    width="60%"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <template v-slot:title>
      <div class="flex mr-200">
        <div class="flex-1">出入金记录</div>
        <el-radio-group v-model="tabPosition" size="mini" @change="handleSearch">
          <el-radio-button label="1">出金</el-radio-button>
          <el-radio-button label="0">入金</el-radio-button>
        </el-radio-group>
      </div>
    </template>
    <div v-loading="loading" class="money-record-content">
      <query-table v-show="dialogVisible && tabPosition === '1'" ref="queryTableOut" :colums="columsOut" :params="paramsOut" :request="request" />
      <query-table v-show="dialogVisible && tabPosition === '0'" ref="queryTableIn" :colums="columsIn" :params="paramsIn" :request="request">
        <template v-slot:operator="{ data }">
          <el-button v-if="data.status === '1' || data.status === '3' || retryStatus" type="text" size="mini" @click="handleRetryIn(data)">重新发起</el-button>
        </template>
      </query-table>
    </div>
  </el-dialog>
</template>

<script>
import QueryTable from '@/components/queryTable'
import { fixNum, toDate } from '@/utils'
import { getApi } from '@/utils/request'
import { mapState } from 'vuex'
export default {
  name: 'MoneyRecord',
  components: {
    QueryTable
  },
  props: {
    bidInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      tabPosition: '1',
      columsOut: [
        {
          label: '申请时间',
          name: 'applydate',
          fixed: 'left',
          minWidth: 160,
          format: 'datetime'
        },
        {
          label: '申请编号',
          name: 'appsheetserialno',
          fixed: 'left',
          minWidth: 150
        },
        {
          label: '出金订单号',
          name: 'orderno',
          minWidth: 150
        },
        {
          label: '收款方名称',
          name: 'accountname',
          minWidth: 220
        },
        {
          label: '收款方卡号',
          name: 'bankaccountno',
          minWidth: 200
        },
        {
          label: '银行名称',
          name: 'bankname',
          minWidth: 220
        },
        {
          label: '银行支行号',
          name: 'banklineno',
          minWidth: 150
        },
        {
          label: '银行支行名称',
          name: 'bankbranch',
          minWidth: 220
        },
        {
          label: '出金金额',
          name: 'applyamount',
          minWidth: 160,
          format: 'money'
        },
        {
          label: '状态',
          name: 'statusname',
          minWidth: 100
        },
        {
          label: '出款订单号',
          name: 'payorderno',
          minWidth: 150
        },
        {
          label: '出款金额',
          name: 'payamount',
          minWidth: 160,
          format: 'money'
        },
        {
          label: '出款时间',
          name: 'paydate',
          minWidth: 160,
          format: 'datetime'
        },
        {
          label: '出款渠道',
          name: 'paychannelname',
          minWidth: 150
        }
      ],
      paramsOut: {
        direction: '1',
        pageNumber: '1',
        pageSize: '5'
      },
      columsIn: [
        {
          label: '申请时间',
          name: 'applydate',
          minWidth: 160,
          fixed: 'left',
          format: 'datetime'
        },
        {
          label: '申请编号',
          name: 'appsheetserialno',
          fixed: 'left',
          minWidth: 150
        },
        {
          label: '入金订单号',
          name: 'orderno',
          minWidth: 150
        },
        {
          label: '入金金额',
          name: 'applyamount',
          minWidth: 160,
          format: 'money'
        },
        {
          label: '状态',
          name: 'statusname',
          minWidth: 140
        },
        {
          label: '付款订单号',
          name: 'payorderno',
          minWidth: 150
        },
        {
          label: '付款渠道',
          name: 'paychannelname',
          minWidth: 220
        },
        {
          label: '付款金额',
          name: 'payamount',
          minWidth: 160,
          format: 'money'
        },
        {
          label: '付款时间',
          name: 'paydate',
          minWidth: 160,
          format: 'datetime'
        }
        //, {
        //   label: '操作',
        //   name: 'operator',
        //   fixed: 'right',
        //   minWidth: 100,
        //   slot: true
        // }
      ],
      paramsIn: {
        direction: '0',
        pageNumber: '1',
        pageSize: '5'
      },
      request: {
        soc: 'kingdom.kfts.get_pay_apply_info'
      },

      loading: false
    }
  },
  computed: mapState({
    // 是否可以重新发起入金
    retryStatus(state) {
      const startBidTime = toDate(this.bidInfo.bidstartdate + this.bidInfo.bidstarttime).getTime()
      return state.system.time.getTime() < startBidTime
    }
  }),
  created() {
  },
  methods: {
    handleShow() {
      this.handleSearch(this.tabPosition)
      this.dialogVisible = true
    },
    handleSearch(tabPosition) {
      if (tabPosition === '1') {
        if (this.$refs.queryTableOut?.handleSearch) this.$refs.queryTableOut.handleSearch()
      } else {
        if (this.$refs.queryTableIn?.handleSearch) this.$refs.queryTableIn.handleSearch()
      }
    },
    // 入金重试
    handleRetryIn(data) {
      this.$confirm(`请确认重新发起缴纳竞买保证金申请，申请编号<span class="cl-orange font-medium mr-50 ml-50">${data.appsheetserialno}</span>，入金金额<span class="cl-orange font-medium mr-50 ml-50">${fixNum(data.applyamount, 2)}元</span>，提交成功后将不能撤销和修改！是否继续提交？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.loading = true
        getApi('kingdom.kfts.client_pay_apply_retry', 'V1.0', {
          appsheetserialno: data.appsheetserialno
        }).then((kdjson) => {
          this.loading = false
          const flag = kdjson.flag
          const items = kdjson.items
          if (flag === '1' && items) {
            this.dialogVisible = false
            window.open(items[0].cashierUrl)
            // 刷新资金信息
            // this.$emit('retryed')
            // this.$message({
            //   message: kdjson.msg,
            //   type: 'success'
            // })
          } else {
            this.$message({
              message: kdjson.msg || '重新发起失败！',
              type: 'error'
            })
          }
        }).catch((kdjson) => {
          this.loading = false
          this.$message({
            message: kdjson.msg,
            type: 'error'
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
// .money-record-content{
//   min-height: 354px;
// }
</style>
