<!--
 * @Author: 段海兵
 * @Date: 2022-11-08 16:09:07
 * @LastEditors: 段海兵
 * @Description: 表格查询组件-form
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div v-if="search && search.length" class="search-form">
    <el-form ref="searchForm" :inline="true" :model="form" :rules="rules">
      <el-form-item v-for="item in search" :key="item.name" :label="item.label" :prop="item.name">
        <el-input v-if="item.type === 'input'" v-model="form[item.name]" :placeholder="item.placeholder" clearable autocomplete="off" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button :loading="loading" icon="el-icon-close" @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    search: {
      type: Array,
      default: () => ([])
    },
    params: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      rules: {}
    }
  },
  watch: {
    search: {
      deep: true,
      handler(n) {
        this.initDate(n)
      }
    }
  },
  created() {
    this.initDate(this.search)
  },
  methods: {
    // 初始化数据
    initDate(search) {
      const form = {}
      const rules = {}
      search.forEach(item => {
        form[item.name] = this.params[item.name] || ''
        rules[item.name] = []
      })
      this.form = form
      this.rules = rules
    },
    handleSearch() {
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.$emit('searched', this.form)
        }
      })
    },
    resetForm() {
      this.$refs.searchForm.resetFields()
      this.$emit('searched', {})
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
