<!--
 * @Author: 段海兵
 * @Date: 2022-10-27 13:35:26
 * @LastEditors: 段海兵
 * @Description: 凭证打印
 * @email: duanhb@bayconnect.com.cn
-->
<template>
  <div class="print">
    <span class="print-btn" @click="handleShow"><slot /></span>
    <el-dialog
      class="print-dialog"
      title="凭证打印"
      width="60%"
      :visible.sync="dialogVisible"
      append-to-body
      destroy-on-close
    >
      <div id="print-container" class="print-container">
        <h4 class="t-c">{{ bidInfo.fundcode }}竞价成交凭证</h4>
        <table class="print-table">
          <tr>
            <td class="t-c"> 单位名称 </td>
            <td colspan="3">{{ accountInfo.name }}</td>
          </tr>
          <tr>
            <td class="t-c"> 交易账号 </td>
            <td>{{ accountInfo.custno }}</td>
            <td class="t-c"> 成交日期 </td>
            <td>{{ bidEnrollInfo.committransactiondate | formatDate }}</td>
          </tr>
          <tr>
            <td class="t-c"> 产品名称 </td>
            <td>{{ bidInfo.fundname }}</td>
            <td class="t-c"> 产品代码 </td>
            <td>{{ bidInfo.fundcode }}</td>
          </tr>
          <tr>
            <td class="t-c"> 交易方式 </td>
            <td>有偿竞价</td>
            <td class="t-c"> 成交方向 </td>
            <td>竞价买入</td>
          </tr>
          <tr>
            <td class="t-c"> 成交价格 </td>
            <td>{{ bidEnrollInfo.dealprice | fixNum(2) }}元/吨</td>
            <td class="t-c"> 成交数量 </td>
            <td>{{ bidEnrollInfo.dealvol | fixNum }}吨</td>
          </tr>
          <tr>
            <td class="t-c"> 成交金额 </td>
            <td>{{ bidEnrollInfo.dealamount | fixNum(2) }}元</td>
            <td class="t-c"> 手续费 </td>
            <td>{{ bidEnrollInfo.totalcharge | fixNum(2) }}元</td>
          </tr>
          <tr>
            <td class="t-c"> 备注 </td>
            <td colspan="3" v-html="voucherPrintTips" />
          </tr>
        </table>
        <div class="print-footer">
          <p>日期：{{ systemTime }}</p>
          <p>北京绿色交易所有限公司</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = fasle">取消</el-button>
        <el-button
          v-print="{
            id: '#print-container',
            popTitle: '北京市碳排放权电子交易平台'
          }"
          type="success"
        >打印</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { renderWebSetting } from '@/setting'
const setting = renderWebSetting()
export default {
  name: 'Print',
  props: {
    bidInfo: {
      type: Object,
      default: () => ({})
    },
    bidEnrollInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: mapState({
    systemTime: state => (state.system.time.format('yyyy年MM月dd日')),
    accountInfo: state => state.account.accountInfo,
    // 竞价成交凭证打印提示
    voucherPrintTips() {
      return setting.voucherPrintTips
    }
  }),
  created() {},
  methods: {
    handleShow() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.print-btn{
  color: #409EFF;
  cursor: pointer;
}
</style>
